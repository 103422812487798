import React from "react";
import { ReactComponent as LoadingIcon } from "../assets/loading-arrow.svg";
import { ReactComponent as RotatorIcon } from "../assets/icons/360 degree rotator icon.svg";
class Rsvp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  hideSpinner = () => {
    this.props.setShowLoader(false);
  };

  render() {
    return (
      <div className="threeDViewer">
        {this.props.showLoader ? (
          <div className="threedee-container loader">
            <LoadingIcon
              fill="#000"
              width="1.25rem"
              height="2rem"
              strokeWidth={1}
              className="rotating"
            />
            <p>loading...</p>
          </div>
        ) : null}
        {this.props.product_id && (
          <iframe
            style={
              this.props.showLoader
                ? { width: "0px", height: "0px" }
                : { opacity: 1 }
            }
            id="the-iframe"
            className="threedee-container"
            title="3D Viewer"
            src={`https://viewer.shape.helloar.io/?id=${this.props.product_id}&transparent=true`}
            allow="xr-spatial-tracking"
            onLoad={this.hideSpinner}
            allowFullScreen={true}
            frameBorder="0"
          />
        )}
        <RotatorIcon width="50px" height='30px' className="RotatorIcon" />
        <p className="_3DViewerFooter">Click and drag on image to rotate</p>
      </div>
    );
  }
}
export default Rsvp;
// import { useEffect, useRef, useState } from "react";

// export default function _3DViewer({ product_id }: { product_id: string }) {
//   const [iframeHasLoaded, setIframeHasLoaded] = useState(false);
//   const iframeRef = useRef<HTMLIFrameElement>(null);

//   useEffect(() => {
//     if (iframeRef.current) {
//       console.log(iframeRef.current.contentDocument);
//       iframeRef.current.contentDocument?.addEventListener("onload", () => {
//         setIframeHasLoaded(true);
//         console.log("loaded");
//       });
//       iframeRef.current.addEventListener("onload", () => {
//         setIframeHasLoaded(true);
//         console.log("loaded");
//       });
//     }
//   }, [iframeRef.current]);

//   // if (!iframeHasLoaded) {
//   //   return <div>loading</div>;
//   // }
//   return (
//     <div className="threed-viewer-container">
//       {iframeHasLoaded ||
//         (true && (
//           <iframe
//             ref={iframeRef}
//             id="the-iframe"
//             className="threedee-container"
//             title="3D Viewer"
//             src={`https://brands.helloar.io/p/${product_id}/embed`}
//             frameBorder="0"
//           ></iframe>
//         ))}
//       <p>Click and drag on image to rotate</p>
//     </div>
//   );
// }
